exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-dlarodzicow-js": () => import("./../../../src/pages/dlarodzicow.js" /* webpackChunkName: "component---src-pages-dlarodzicow-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-grupaaniolkow-js": () => import("./../../../src/pages/grupaaniolkow.js" /* webpackChunkName: "component---src-pages-grupaaniolkow-js" */),
  "component---src-pages-grupapromyczkow-js": () => import("./../../../src/pages/grupapromyczkow.js" /* webpackChunkName: "component---src-pages-grupapromyczkow-js" */),
  "component---src-pages-grupasloneczek-js": () => import("./../../../src/pages/grupasloneczek.js" /* webpackChunkName: "component---src-pages-grupasloneczek-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-rekrutacja-js": () => import("./../../../src/pages/rekrutacja.js" /* webpackChunkName: "component---src-pages-rekrutacja-js" */)
}

